import axios from "axios";
// http://localhost:4000
// https://back-page-exsis.azurewebsites.net
 
export function getRoles(idioma) { 
  if (idioma === "en") {
    return axios.get("https://back-page-exsis.azurewebsites.net/api/role/en");
  } else {
    return axios.get("https://back-page-exsis.azurewebsites.net/api/role");
  }
}

export function getInterest(idioma) {
  if (idioma === "en") {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/requestType/en"
    );
  } else {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/requestType"
    );
  }
}

export function getTalentTypes(idioma) {
  if (idioma === "en") {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/requestType/en"
    );
  } else {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/requestType"
    );
  }
}

export function getYearsExperience(idioma) {
  if (idioma === "en") {
    return axios.get("https://back-page-exsis.azurewebsites.net/api/year/en");
  } else {
    return axios.get("https://back-page-exsis.azurewebsites.net/api/year");
  }
}

export function getEnglishLevels() {
  return axios.get(
    "https://back-page-exsis.azurewebsites.net/api/englishLevel"
  );
}

export function getOffers() {
  return axios.get(
    "https://companyms.exsis-recruitment.click/api/job/candidatejobs/3"
  );
}

export function getOffersAllInfo() {
  return axios.get(
    "https://companyms.exsis-recruitment.click/api/job/candidateJobsToExsisPage/3"
  );
}

export function getOffersById(id) {
  return axios.get(
    "https://companyms.exsis-recruitment.click/api/job/candidatejob/" + id
  );
}

export function getProfilesHunting(idioma) {
  if (idioma === "en") {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/profile/hunting/en"
    );
  } else {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/profile/hunting"
    );
  }
}

export function getProfilesAugmentation(idioma) {
  if (idioma === "en") {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/profile/augmentation/en"
    );
  } else {
    return axios.get(
      "https://back-page-exsis.azurewebsites.net/api/profile/augmentation"
    );
  }
}

export function postEmailCompany(body) {
  return axios({
    method: "post",
    url: "https://back-page-exsis.azurewebsites.net/api/company",
    data: body,
  });
}

export function postEmailJob(body) {
  return axios({
    method: "post",
    url: "https://back-page-exsis.azurewebsites.net/api/jobApplication",
    data: body,
  });
}

export function postEmailUser(body) {
  return axios({
    method: "post",
    url: "https://back-page-exsis.azurewebsites.net/api/user",
    data: body,
  });
}

// Resolve promise and set initial props.
export function getPosts() {
  return axios.get("https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/posts");
}

export function getPost(id) {
  return axios.get(
    `https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/posts/${id}`
  );
}

export function getPostBySlug(slug) {
  return axios.get(
    `https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/posts?slug=${slug}`
  );
}

export function getImage(id) {
  return axios.get(
    "https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/media/" + id
  );
}

export function getMedia() {
  return axios.get("https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/media");
}

export function getTags() {
  return axios.get("https://d3dty80hi76ee5.cloudfront.net/wp-json/wp/v2/tags/");
}

export function sendContacUs(body) {
  return axios({
    method: "post",
    url: "https://yjkn4ht2q9.execute-api.us-east-2.amazonaws.com/test/sendcontactusemail",
    data: body,
  });
}

export function sendEmailBootcamp(email) {
  return axios({
    method: "post",
    url: "https://o3rfkrgvgk.execute-api.us-east-2.amazonaws.com/test/addemail",
    data: '"' + email + '"',
  });
}

export function getBlogsRef() {
  return axios.get("https://exsis.cdn.prismic.io/api/v2");
}
 
export function getBlogsPosts(ref) {
  return axios.get(`https://exsis.prismic.io/api/v2/documents/search?ref=${ref}`);  
}